import React, { useEffect, useState } from "react";
import { Body, ButtonYellow } from "./styled";
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import api from "../../../../../service/api";
import { ProgressBar } from "react-loader-spinner";
import { Dropdown } from "primereact/dropdown";
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import { InputSwitch } from 'primereact/inputswitch';

const CadastrarTurma = () => {
    const [datetime24h, setDateTime24h] = useState(null);
    const [datetime24hB, setDateTime24hB] = useState(null);
    const [weekly, setWeekly] = useState(false)
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState("")
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [selectedOptionsProf, setSelectedOptionsProf] = useState(null);
    const [selectedOptionsEvent, setSelectedOptionsEvent] = useState(null);
    const toast = useRef(null);
    const [dataTake, setDataTake] = useState([])

    const moment = require('moment');

    let start = moment(datetime24h).format('YYYY-MM-DD HH:mm:ss');
    let end = moment(datetime24hB).format('YYYY-MM-DD HH:mm:ss');


    const getInfos = (endpoint) => {
        setLoading(true)
        api.get(endpoint)
            .then((res) => {
                console.log(res.data)
                setDataTake(res.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }


    const handleRegister = () => {
        setLoading(true)
        api.post('/registerclass', {
            name: `${selectedOptionsEvent?.name}-${title}`,
            room: selectedOptions?.code,
            weekly: weekly,
            start: start,
            end: end,
            professor: selectedOptionsProf?.name
        })
            .then((res) => {
                setTitle("")
                setDateTime24h("")
                setDateTime24hB("")
                setWeekly(false)
                setLoading(false)
                console.log("deu bom")
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Turma cadastrada com sucesso' });
            })
            .catch((err) => {
                console.log(datetime24hB)
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Erro ao cadastrar turma' });
                setLoading(false)
                console.log(err)
            })
    }

    useEffect(() => {
        getInfos("/takeprofessor")
    }, [])

    useEffect(() => {
        console.log(selectedOptionsProf)
    }, [selectedOptionsProf])

    const options = [
        { name: 'Sala 1', code: 1 },
        { name: 'Sala 2', code: 2 },
        { name: 'Sala 3', code: 3 },
        { name: 'Sala 4', code: 4 },
        { name: 'Salão', code: 5 },
    ];

    const optionsEvents = [
        { name: 'Turma', code: 'Turma' },
        { name: 'Aula Particular', code: 'Aula Particular' },
        { name: 'Evento', code: 'Evento' },
    ];

    const optionsProf = () => {
        let newArray = []
        dataTake.map((itens) => {
            newArray.push({ name: itens.name, code: itens.id })
        })

        return newArray
    }

    return (
        <Body>
            <Toast ref={toast} />
            <div style={{ position: "absolute", zIndex: 10 }}>
                <ProgressBar
                    height="80"
                    width="80"
                    ariaLabel="progress-bar-loading"
                    wrapperStyle={{}}
                    wrapperClass="progress-bar-wrapper"
                    borderColor='black'
                    barColor='#FFCD03'
                    visible={loading}
                />
            </div>
            <view style={{ width: 500, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <text>Selecione a sala</text>
                <Dropdown value={selectedOptions} style={{ marginTop: 10, width: 200 }} onChange={(e) => setSelectedOptions(e.value)} options={options} optionLabel="name"
                    placeholder="Selecione" className="w-full md:w-14rem" />
                        <text style={{marginTop: 10}}>Selecione o tipo de evento</text>
                <Dropdown value={selectedOptionsEvent} style={{ marginTop: 10, width: 200 }} onChange={(e) => setSelectedOptionsEvent(e.value)} options={optionsEvents} optionLabel="name"
                    placeholder="Selecione" className="w-full md:w-14rem" />
                      <text style={{marginTop: 10}}>Selecione o professor</text>
                <Dropdown value={selectedOptionsProf} style={{ marginTop: 10, width: 200 }} onChange={(e) => setSelectedOptionsProf(e.value)} options={optionsProf()} optionLabel="name"
                    placeholder="Selecione" className="w-full md:w-14rem" />
                <InputText placeholder="Título" style={{ marginTop: 10, width: 300 }} value={title} onChange={(e) => setTitle(e.target.value)} />
                <Calendar placeholder="Começa" style={{ marginTop: 10, width: 300 }} id="calendar-24h" value={datetime24h} onChange={(e) => setDateTime24h(e.value)} showTime hourFormat="24" />
                <Calendar placeholder="Termina" style={{ marginTop: 10, width: 300 }} id="calendar-24h" value={datetime24hB} onChange={(e) => setDateTime24hB(e.value)} showTime hourFormat="24" />
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                    <text style={{ marginRight: 15 }}>Toda semana?</text>
                    <InputSwitch checked={weekly} onChange={(e) => setWeekly(e.value)} />
                </div>
                <text style={{fontSize: 20, marginTop: 20, marginBottom: 20}}>{selectedOptions?.name} - {selectedOptionsEvent?.name} - {title} - {selectedOptionsProf?.name}</text>
                <ButtonYellow onClick={() => handleRegister()}>Cadastrar</ButtonYellow>
            </view>



        </Body>
    )
}

export default CadastrarTurma;