import React, { useState } from "react";
import { Body, ButtonYellow } from "./styled";
import QRCode from "qrcode.react";
import { InputText } from "primereact/inputtext";
import api from "../../../../../service/api";
import { Toast } from 'primereact/toast';
import { useRef } from "react";

const ControleDeBaile = () => {
    const [inputText, setInputText] = useState("");
    const [qrValue, setQrValue] = useState("");
    const toast = useRef(null);

    const handleGenerateQR = () => {

        if(inputText === '' ? true : false){
            
            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Preencha o campo QRCode' });

        }else{
            setQrValue(inputText);
            api.post('/registerqrcode', {
                palavra_chave: inputText
            })
                .then((res) => {
                    console.log(res)
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'QRcode cadastrado com sucesso' });
                })
                .catch((err) => {
                    console.timeLog(err)
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Erro ao cadastrar QRcode' });
                })
        }

    };

    return (
        <Body>
            <Toast ref={toast} />
            <div >
                <InputText placeholder="Digite o texto para o QR Code" style={{ marginTop: 10, width: 300 }} value={inputText} onChange={(e) => setInputText(e.target.value)} />
                <ButtonYellow  style={{ marginLeft: 10 }} onClick={handleGenerateQR}>Gerar QR Code</ButtonYellow>
            </div>
            {qrValue && (
                <div style={{ marginTop: 40 }}>
                    <QRCode size={350} value={qrValue} />
                </div>
            )}
        </Body>
    );
};

export default ControleDeBaile;