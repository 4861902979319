import React from "react";
import './styled.css';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          {children}
          <button className="button" onClick={onClose}>Fechar</button>
        </div>
      </div>
    );
  };

  export default Modal;