import React, { useEffect, useState } from "react";
import api from "../../../../../service/api";
import { Body, ButtonFinish, ButtonModal, ButtonModalOff, ButtonModalOn, ButtonOpenModalEdit, ButtonTeacher, ButtonTeacherON, ContainerDouble, ContainerList, ContainerListText, FormCoitainer, InputEdit, ModalBody, ModalText, MonitorList, SelectTeacher } from "./styled";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { Dropdown } from 'primereact/dropdown';
import Modal from 'react-modal';
import { ProgressBar } from "react-loader-spinner";
import { useContext } from "react";
import GlobalStateContext from "../../../../Context/GlobalStateContext";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: "20px",
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);'
    },
};


const FinishPez = () => {
    const [dataTake, setDataTake] = useState([])
    const [dataTakeHist, setDataTakeHist] = useState([])
    const [prodSelect, setProdSelect] = useState(null)
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [selectedOptionsTeacher, setSelectedOptionsTeacher] = useState({});
    const [modalIsOpen, setIsOpen] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalEditHistoric, SetOpenModalEditHistoric] = useState(false);
    const [loading, setLoading] = useState(false)
    const [selectMonitor, setSelectMonitor] = useState()
    const [editPezinhosState, setEditPezinhosState] = useState({
        newPesinhosMonth: '',
        newTurmaFixas: '',
   
    })


    useEffect(() => {
        setSelectedOptions({ name: 'Monitor', code: 'MT' })
    }, [])



    const options = [
        { name: 'Monitor', code: 'MT' },
        { name: 'Professor', code: 'PF' },
        // { name: 'Aluno', code: 'AL' },
    ];
    const getInfos = (endpoint) => {
        setLoading(true)
        api.get(endpoint)
            .then((res) => {
                setDataTake(res.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }


    const clickButton = () => {
        api.get('/closemonth')
            .then((res) => {
                console.log(res);
                updatePezinhosData(); // Chamando a função de atualização
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const buttonActive = (value) => {
        api.post("/editvalidade", {
            id: selectedOptionsTeacher?.id,
            validade: value
        })
            .then((res) => {
                console.log("deu bom")
                getInfos('/takeprofessor')
                setIsOpen(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const updatePezinhosData = () => {
        getInfos('/takemonitor');
    }

    const editPezinhos = () => {
        setLoading(true)
        if (editPezinhosState.newPesinhosMonth || editPezinhosState.newTurmaFixas) {
            api.put('/editpesinho', {
                pesinhos_month: parseFloat(editPezinhosState.newPesinhosMonth),
                turmafixas: parseFloat(editPezinhosState.newTurmaFixas),
                id: selectMonitor
            })
            .then((res) => {
                setLoading(false)
                setOpenModalEdit(false)
                updatePezinhosData();
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
        } else {
            console.log("Nenhum dado de atualização fornecido.");
            setLoading(false);
        }
    }
    
    function openModal(obj) {
        setIsOpen(true);
        setSelectedOptionsTeacher(obj)
    }
    function handleOpenModalEdit(products) {
        setOpenModalEdit(true)
        setSelectMonitor(products?.id)
        setProdSelect(products)

    }
    function handleOpenModalEditHistoric(products) {
        console.log(products)
        SetOpenModalEditHistoric(true)
        api.post("/gethistoric", {
            monitor: products?.name
        })
            .then((res) => {
                setDataTakeHist(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

    useEffect(() => {
        if (selectedOptions?.code === 'MT') {
            getInfos('/takemonitor')
        } else if (selectedOptions?.code === 'PF') {
            getInfos('/takeprofessor')
        } else {

        }
    }, [selectedOptions])


    const actionTemplateShowEdit = (products, column) => {
        return (
            <div style={{ zIndex: 10 }}>
                {products.active === 0 ?
                    <ButtonTeacher onClick={() => openModal(products)}>Pendente</ButtonTeacher>
                    :
                    <ButtonTeacherON onClick={() => openModal(products)}>Ativo</ButtonTeacherON>
                }

            </div>
        )
    }

    const actionTemplateShowEditMonitor = (products, column) => {
        return (
            <div style={{ zIndex: 10 }}>
                {products.active === 0 ?
                    <ButtonTeacher onClick={() => openModal(products)}>Não ativo</ButtonTeacher>
                    :
                    <ButtonTeacherON onClick={() => openModal(products)}>Ativo</ButtonTeacherON>
                }

            </div>
        )
    }

    const actionTemplateShowEditAct = (products, column) => {
        return (
            <div>
                {products?.elogio === null ?
                    <text>Nenhum elogio</text>
                    :
                    <text>{products?.elogio}</text>
                }

            </div>
        )
    }

    const actionTemplateShowEditCrit = (products, column) => {
        return (
            <div>
                {products?.critica === null ?
                    <text>Nenhuma crítica</text>
                    :
                    <text>{products?.critica}</text>
                }

            </div>
        )
    }

    const actionTemplateButton = (products, column) => {
        return (
            <div>
                <ButtonOpenModalEdit onClick={() => handleOpenModalEdit(products)}>Editar</ButtonOpenModalEdit>
            </div>
        )
    }
    const actionTemplateButtonHistoric = (products, column) => {
        return (
            <div>
                <ButtonOpenModalEdit onClick={() => handleOpenModalEditHistoric(products)}>Histórico</ButtonOpenModalEdit>
            </div>
        )
    }



    return (
        <Body>
            <div style={{ position: "absolute", zIndex: 10 }}>
                <ProgressBar
                    height="80"
                    width="80"
                    ariaLabel="progress-bar-loading"
                    wrapperStyle={{}}
                    wrapperClass="progress-bar-wrapper"
                    borderColor='black'
                    barColor='#FFCD03'
                    visible={loading}
                />
            </div>
            <FormCoitainer style={{ marginTop : "20px"}}>
                <div style={{ justifySelf: "flex-start", alignSelf: "flex-start" }}>
                    <Dropdown value={selectedOptions} onChange={(e) => setSelectedOptions(e.value)} options={options} optionLabel="name"
                        placeholder="Selecione" className="w-full md:w-14rem" />
                </div>
                <ContainerDouble>
                    {selectedOptions?.code === "MT" ?

                        <DataTable value={dataTake} paginator responsiveLayout="scroll"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                            paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} tableStyle={{ minWidth: '40rem' }} style={{ width: "100%" }}>
                            <Column style={{ width: 10, height: 40, fontSize: 12 }} field="name" header="Nome"></Column>
                            <Column style={{ width: 10, fontSize: 12 }} field="pesinhos_years" header="Total pezinho"></Column>
                            <Column style={{ width: 10, fontSize: 12 }} field="pesinhos_month" header="Pezinho geral"></Column>
                            <Column style={{ width: 120, fontSize: 12 }} field="avulso" header="Aulas avulsas"></Column>
                            <Column style={{ width: 10, fontSize: 12 }} field="subs" header="Substituição"></Column>
                            <Column style={{ width: 10, fontSize: 12 }} field="subsprof" header="Substituição Prof."></Column>
                            <Column style={{ width: 10, fontSize: 12 }} field="falta" header="Falta"></Column>
                            <Column style={{ width: 120, fontSize: 12 }} field="tempodecasa" header="Tempo de casa"></Column>
                            <Column style={{ width: 120, fontSize: 12 }} field="baile" header="Baile"></Column>
                            <Column style={{ width: 120, fontSize: 12 }} field="fiscal" header="Fiscal"></Column>
                            <Column style={{ width: 10, fontSize: 12 }} field="turmafixas" header="Turmas fixas"></Column>
                            <Column style={{ width: 10, fontSize: 12 }} field="elogio" body={actionTemplateShowEditAct} header="Elogio"></Column>
                            <Column style={{ width: 10, fontSize: 12 }} field="critica" body={actionTemplateShowEditCrit} header="Crítica"></Column>
                            <Column style={{ width: 10, fontSize: 12 }} field="active" header='Monitor fiscal ativo? ' body={actionTemplateShowEditMonitor}></Column>
                            <Column style={{ width: 10, fontSize: 12 }} field='id' header='Editar' body={actionTemplateButton}> </Column>
                            <Column style={{ width: 10, fontSize: 12 }} field='id' header='Histórico' body={actionTemplateButtonHistoric}> </Column>
                        </DataTable>


                        :

                        <DataTable value={dataTake} paginator responsiveLayout="scroll"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                            paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} >
                            <Column field="name" header="Name"></Column>
                            {/* <Column field="elogio" header="Elogio"></Column>
                <Column field="critica" header="Crítica"></Column> */}
                            <Column field="active" body={actionTemplateShowEdit} header="Status"></Column>
                        </DataTable>



                    }
                </ContainerDouble>

            </FormCoitainer>
            <ButtonFinish onClick={() => clickButton()}>Encerrar o mês</ButtonFinish>
            {modalIsOpen ?
                <ModalBody>
                    <ModalText>{selectedOptionsTeacher?.active === 0 ? 'Você selecionou parar "Ativar". Confirmar essa ação?' : 'Você selecionou para "Desativar" . Confirmar essa ação?'}</ModalText>
                    <div>
                        <ButtonModalOn onClick={() => buttonActive(selectedOptionsTeacher?.active === 0 ? 1 : 0)} >Confirmar</ButtonModalOn>
                        <ButtonModalOff onClick={() => setIsOpen(false)}>Cancelar</ButtonModalOff>

                    </div>
                </ModalBody> :

                null


            }

            {openModalEdit ?
                <ModalBody>
                    <div style={{ display: "flex", flexDirection: "collum" }}>
                        <InputEdit value={editPezinhosState.newPesinhosMonth} onChange={(e) => setEditPezinhosState(prevState => ({ ...prevState, newPesinhosMonth: e.target.value }))} placeholder="Pezinho gerado"></InputEdit>
                        <InputEdit value={editPezinhosState.newTurmaFixas} onChange={(e) => setEditPezinhosState(prevState => ({ ...prevState, newTurmaFixas: e.target.value }))} placeholder="Turmas Fixas"></InputEdit>
                       
                   </div>
                    <div>
                        <ButtonModalOn onClick={() => editPezinhos()} >Salvar</ButtonModalOn>
                        <ButtonModalOff onClick={() => setOpenModalEdit(false)}>Cancelar</ButtonModalOff>
                    </div>
                </ModalBody> :
                null
            }

            {openModalEditHistoric ?
                <ModalBody>
                    <div style={{ display: "flex", flexDirection: "column" }}>

                    </div>
                    <div>
                        {dataTakeHist.length === 0 ?
                            <div style={{ display: "flex", flexDirection: "column", fontSize: 20, margin: 10 }}>
                                <text>Nenhum histórico de chamada</text>
                            </div> :

                            <>
                                {dataTakeHist.map((itens) => {
                                    const date = new Date(itens?.created_at).getDay() + 1
                                    const dateMonth = new Date(itens?.created_at).getMonth()
                                    const dateYears = new Date(itens?.created_at).getFullYear()
                                    return (
                                        <div style={{ display: "flex", flexDirection: "column", fontSize: 10, margin: 10 }}>
                                            <div>
                                                <text>{itens?.id}- </text>
                                                <text>{itens?.class}</text>
                                            </div>
                                            <text>{date.toString().length === 1 ? `0${date}` : date}/{dateMonth.toString().length === 1 ? `0${dateMonth}` : dateMonth}/{dateYears}</text>
                                        </div>
                                    )
                                })}
                            </>

                        }


                        <ButtonModalOn onClick={() => SetOpenModalEditHistoric(false)} >Voltar</ButtonModalOn>
                    </div>
                </ModalBody> :
                null
            }

        </Body>
    )
}

export default FinishPez;