import React, { useEffect, useRef, useState } from "react";
import { Body, ButtonYellow, FormEvent, InputEvent, InputEventSelect, InputEventText } from "./styled";
import { Calendar } from 'primereact/calendar';
import { InputText } from "primereact/inputtext";
import { useSession, useSupabaseClient, useSessionContext } from "@supabase/auth-helpers-react";
import { Dropdown } from "primereact/dropdown";
import api from "../../../../../service/api";
import { Toast } from 'primereact/toast';
import CalendarMake from "./components/CalendarMake";



const Agenda = () => {
  const [myEventsList, setMyEventsList] = useState([])
  const [myEventsRoom, setMyEventsRoom] = useState([])
  const [datetime24h, setDateTime24h] = useState(null);
  const [datetime24hB, setDateTime24hB] = useState(null);
  const [title, setTitle] = useState("")
  const [Room, setRoom] = useState(1)
  const [selectedOptions, setSelectedOptions] = useState({ name: 'Sala 1', code: 1 });


  const session = useSession();
  const supabase = useSupabaseClient();
  const { isLoading } = useSessionContext();
  const toast = useRef(null);


  const options = [
    { name: 'Sala 1', code: 1 },
    { name: 'Sala 2', code: 2 },
    { name: 'Sala 3', code: 3 },
    { name: 'Sala 4', code: 4 },
    { name: 'Salão', code: 5 },
  ];



  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await api.post('/takeclass', {
          room: selectedOptions?.code
        });
        const events = response.data.flatMap(item => {
          const baseEvent = {
            title: item.name,
            allDay: false,
            start: new Date(item?.start),
            end: new Date(item?.end),
          };
  
  
          if (item?.weekly === 1) {
            return Array.from({ length: 30 }, (_, i) => ({
                ...baseEvent,
                start: new Date(baseEvent.start).setDate(baseEvent.start.getDate() + i * 7),
                end: new Date(baseEvent.end).setDate(baseEvent.end.getDate() + i * 7)
            }));
        }
          return [baseEvent];
        });
  

        setMyEventsRoom(events);
        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Busca de turmas realizada com sucesso' });
      } catch (error) {
        console.error(error);
        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Não foi possível fazer sua consulta' });
      } 
    };

    fetchClasses();
  }, [selectedOptions]);



  return (
    <Body>
      <Toast ref={toast} />
      <Dropdown value={selectedOptions} style={{  alignSelf: "center", marginRight: 200 }} onChange={(e) => setSelectedOptions(e.value)} options={options} optionLabel="name"
        placeholder="Selecione a Sala" className="w-full md:w-14rem" />
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <CalendarMake myEventsRoom={myEventsRoom}/>
      </div>
    </Body>
  )
}

export default Agenda;