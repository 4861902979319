import React, { useState } from "react";
import { Body, ButtonLnd, CardLogin, InputLnd, InputsContainers, Logo, TexteTeste } from "./styled";
import lnd from "../../../assets/img/LND_2.png"
import api from "../../../service/api";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import GlobalStateContext from "../../Context/GlobalStateContext";
import { ProgressBar } from "react-loader-spinner";
import { Toast } from 'primereact/toast';
import { useRef } from "react";



const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const toast = useRef(null);
    const navigation = useNavigate()

    const {setUserInfos} = useContext(GlobalStateContext);

    const loginHandle = () => {
        setLoading(true)
        api.post('/login',{
            email: email,
            password: password
        })
        .then((res) => {
            localStorage.setItem("@token", res.data.token.token)
            navigation('/home')
            setUserInfos(res.data.user)
            setLoading(false)
        })
        .catch((err) => {
            console.log(err)
            setLoading(false)
            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Não foi possivel realizar o login' });
        })
    }


    return(
        <Body>
            <div style={{position:"absolute", zIndex:10}}>
            <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor = 'black'
                barColor = '#FFCD03'
                visible={loading}
                />
            </div>
            <Toast ref={toast}/>
            <CardLogin>
                <Logo src={lnd}/>
                <InputsContainers>
                    <InputLnd placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)}></InputLnd>
                    <InputLnd type="password" placeholder="Senha" value={password} onChange={(e) => setPassword(e.target.value)}></InputLnd>
                    <ButtonLnd onClick={() => loginHandle()}>Login</ButtonLnd>
                </InputsContainers>
            </CardLogin>
        </Body>
    )

}

export default Login;