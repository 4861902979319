import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './Calendar.css'; // Adicione esta linha
import Modal from '../Modal';


const CalendarMake = ({ myEventsRoom }) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);

  const [events, setEvents] = useState([

  ]);


  function getTextBeforeHyphen(text) {
    const index = text.indexOf('-');
    if (index === -1) return text; // Retorna a string original se não encontrar um hífen
    return text.substring(0, index);
}

  useEffect(() => {
    setEvents([
      ...myEventsRoom.map(event => ({
        title: event.title,
        start: moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(event.end).format('YYYY-MM-DD HH:mm:ss')
      }))
    ]);
  }, [myEventsRoom]);



  const openModal = (day) => {
    // Filtrar eventos do dia selecionado
    const dayEvents = events?.filter(e =>
      moment(day).isSame(moment(e.start), 'day') ||
      moment(day).isBetween(moment(e.start), moment(e.end), 'day', '[]')
    );
    // Armazenar a data e os eventos no estado
    setSelectedDay({ date: day, events: dayEvents });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDay(null);
  };

  const addEvent = (day) => {
    const title = prompt('Digite o título do evento:');
    if (title) {
      const newEvent = {
        title,
        date: day.format('YYYY-MM-DD') // Armazena apenas a data sem o tempo
      };
      setEvents([...events, newEvent]);
    }
  };


  const generateCalendarGrid = () => {
    const startDay = currentDate.clone().startOf('month').startOf('week');
    const endDay = currentDate.clone().endOf('month').endOf('week');

    const grid = [];
    let date = startDay.clone().subtract(1, 'day');

    while (date.isBefore(endDay, 'day')) {
      grid.push(
        Array(7).fill(0).map(() => date.add(1, 'day').clone())
      );
    }

    return grid;
  };

  // Função para ir para o mês anterior
  const goToPreviousMonth = () => {
    setCurrentDate(prev => prev.clone().subtract(1, 'month'));
  };

  // Função para ir para o próximo mês
  const goToNextMonth = () => {
    setCurrentDate(prev => prev.clone().add(1, 'month'));
  };


  const selectColorText = (e) => {
    if(getTextBeforeHyphen(e) === 'Aula Particular'){
      return "#fcdb17"
    }else if(getTextBeforeHyphen(e) === 'Turma'){
      return "#4e93e8"
    }else{
      return "#ea5674"
    }
  } 



  const renderCalendar = () => {
    const grid = generateCalendarGrid();
    const today = moment();
    const format = 'YYYY-MM-DD';

    return (
      <div style={{ width: "100vh" }}>
        {/* Cabeçalho dos dias da semana */}
        <div className="week day-header">
          {['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'].map(day => (
            <div key={day}>{day}</div>
          ))}
        </div>
        {/* Células do calendário */}
        {grid.map((week, idx) => (
          <div className="week" key={idx}>
            {console.log(events)}
            {week.map(day => (
              <div
                key={day.format('DD MM YYYY')}
                className={`day-cell ${day.isSame(today, 'day') ? 'today' : ''} 
                          ${!day.isSame(today, 'month') ? 'dimmed' : ''}`}
                onClick={() => openModal(day)}
              >
                {day.format('D')}
                {events.filter(e =>
                  moment(day).isSame(moment(e.start), 'day') ||
                  moment(day).isBetween(moment(e.start), moment(e.end), 'day', '[]') // Inclui o fim do evento
                ).map((e, index) => (
                  <div key={index} className="event" style={{color:  selectColorText(e.title)}}>
                    {getTextBeforeHyphen(e.title)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="calendar-container">
      <div className="header">
        <button className="button" onClick={goToPreviousMonth}>Mês Anterior</button>
        <span>{currentDate.format('MMMM YYYY')}</span>
        <button className="button" onClick={goToNextMonth}>Próximo Mês</button>
      </div>
      <div className="grid">
        {renderCalendar()}
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {selectedDay && (
          <div>
            <h2>Eventos para {selectedDay.date.format('DD/MM/YYYY')}</h2>
            <ul>
              {selectedDay.events.map((event, index) => (
                <li key={index}>
                  <strong>{event.title}</strong><br />
                  {`Começa: ${moment(event.start).format('HH:mm')}`}<br />
                  {`Termina: ${moment(event.end).format('HH:mm')}`}
                </li>
              ))}
            </ul>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CalendarMake;